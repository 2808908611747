import React from 'react';
import Common from '../Common';
import {Link} from 'react-router-dom';

class Recyclebin extends Common{
    constructor(props){
        super(props);
        this.state={}
    }
    clearSelectStatus=()=>{
        let items = document.querySelectorAll("div.menu div.selected")
        items.forEach(function(v,k){
            v.className=v.className.replace('selected','')
        })
    }
    setSelected=(options)=>{
        let target = options.target
        while(target.className.indexOf('menu-item-text')===-1){
            target = target.parentElement
        }
        this.clearSelectStatus()
        target.className = 'menu-item-text selected'
    }
    render(){
        return(
            <div className='menu-item-box box-btm'>
                <Link to="/recyclebin">
                <div className='menu-item-text' onClick={this.setSelected}>
                    <i className='rec-menu-more '></i>
                    <div className='menu-item'>
                        <i className='delete-icon'></i><span className='txt'>回收站</span>
                    </div>
                </div>
                </Link>
            </div>
        )
    }
    
}

export default Recyclebin;