import React from 'react';
import Common from '../Common';
import {Link} from 'react-router-dom';
import { message, Modal} from 'antd';
const { confirm } = Modal;
class MenuItem extends Common {
    constructor(props) {
        super(props)
        this.state = {
            open_status:false,
            menu:this.props.menu,
            open_idx:-1,
            mousemenu:{
                status:false
            },
            visible:false,
            cateTitle:'',
            catePid:this.props.catePid,
            cateIdx:0,
            modelType:1,
            modelTitle:'添加文件夹',
        }
    }
    componentDidUpdate(prevprops){
        if(prevprops.menu!==this.props.menu){
            this.setState({
                menu:this.props.menu
            })
        }
    }
    openFolder=(options)=>{
        let target = options.target
        while(target.className.indexOf('menu-item-text')===-1){
            target = target.parentElement
        }
        this.clearSelectStatus()
        target.className = 'menu-item-text selected'
        let idx = options.target.dataset.idx
        let menu = this.state.menu
        menu[idx].open = !menu[idx].open
        menu[idx].selected = !menu[idx].selected
        if(this.state.open_idx>-1&&this.state.open_idx!==idx){
            menu[this.state.open_idx].open = false
        }
        this.setState({
            menu:menu,
            open_idx:idx
        })
    }
    contextmenu=(options)=>{
        if(this.props.type!=='recyclebin'){
            options.preventDefault()
            
            let mouseMenus = document.getElementsByClassName('mouse-menu')
            if(mouseMenus.length>0){
                for(let i=0;i<mouseMenus.length;i++){
                    let item = mouseMenus[i]
                    item.setAttribute('class','mouse-menu')
                }
            }
            let idx = options.target.dataset.idx
            let catePid = this.state.menu[idx].id
            this.setState({
                catePid:catePid,
                cateIdx:idx
            })
            var target = document.getElementById('main-menu'+this.props.catePid)
            target.setAttribute('style','top:'+options.clientY+'px;left:'+options.clientX+'px')
            target.setAttribute('class','mouse-menu open')
        }
    }
    handleClick=()=>{
        let mouseMenus = document.getElementsByClassName('mouse-menu')
        if(mouseMenus.length>0){
            for(let i=0;i<mouseMenus.length;i++){
                let item = mouseMenus[i]
                item.setAttribute('class','mouse-menu')
            }
        }
    }
    componentWillUnmount() {
        // 移除事件监听
        document.removeEventListener('contextmenu', this.contextmenu)
        document.removeEventListener('click', this.handleClick)
    }
    showModal=()=>{
        this.setState({
            visible:true,
            modelType:1,
            modelTitle:'添加文件夹'
        })
    }
    renameCate=()=>{
        let cateTitle = this.state.menu[this.state.cateIdx].title
        this.setState({
            visible:true,
            modelType:2,
            modelTitle:'重命名文件夹',
            cateTitle:cateTitle,
        })
    }

    removeCate=()=>{
        let _self = this;    
        let menu = this.state.menu;
        let Cate = this.state.menu[this.state.cateIdx];
        console.log(menu);
        let cateid = Cate.id; 
        confirm({
            title: '是否确认删除'+Cate.title+'？',
            icon: false,
            content: '',
            okText: '删除',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                _self.delAction(cateid)
                menu.splice(_self.state.cateIdx,1)
                _self.setState({
                    menu:menu,
                    visible:false,
                })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    delAction(id){
        this.request({
            url:'/delcate/'+id,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    message.success(res.data.message)
                    
                }else{
                    message.error(res.data.message)
                }
            }
        })
    }
    addCollection=()=>{
        this.setState({
            visible:true
        })
    }
    ModelOk = () => {
        let menu = this.state.menu;
        let _self = this;
        console.log(this.state.modelType)
        if(this.state.modelType===1){
            let cateTitle = this.state.cateTitle;
            let parentid = this.state.catePid;
            let params = {title:cateTitle,parentid:parentid}
            this.request({
                url:'/cate',
                data:params,
                method:'POST',
                success:function(res){
                    if(res.data.code==='0'){
                        let webData = res.data.data;
                        menu[_self.state.cateIdx].items.push({id:webData,title:cateTitle,num:0,items:[],open:false})
                        _self.setState({
                            menu:menu,
                            visible:false,
                        })
                    }else{
                        message.error(res.data.message)
                    }
                }
            })
        }else if(this.state.modelType===2){
            let cateTitle = this.state.cateTitle;
            let parentid = menu[this.state.cateIdx].id;
            let params = {title:cateTitle}
            this.request({
                url:'/cate/'+parentid,
                data:params,
                method:'POST',
                success:function(res){
                    if(res.data.code==='0'){
                        menu[_self.state.cateIdx].title = cateTitle
                        _self.setState({
                            menu:menu,
                            visible:false,
                        })
                    }else{
                        message.error(res.data.message)
                    }
                }
            })
        }
    }

    ModelCancel = () => {
        this.setState({
            visible:false
        })
    }
    setCollectionValue=(options)=>{
        let value = options.target.value;
        this.setState({
            cateTitle:value
        })
    }
    clearSelectStatus=()=>{
        let items = document.querySelectorAll("div.menu div.selected")
        items.forEach(function(v,k){
            v.className=v.className.replace('selected','')
        })
    }
    // setSelected=(options)=>{
       
    // }
    render() {
        let pdLef = parseInt(this.props.level)*38; 
        let pdstyle = {"paddingLeft":pdLef+"px"}
        return (
            <>
                {this.state.menu.map((item,idx)=>{
                    return (
                    <li className='menu-item-box' key={item.id}  onContextMenu={this.contextmenu}  data-idx={idx}>
                        <Link to={"/home/"+item.id} data-idx={idx} title={item.title}>
                        <div className={'menu-item-text '+(item.open_status?'selected':'')} style={pdstyle} onClick={this.openFolder}  data-idx={idx}>
                            {item.items.length>0?<i className={'menu-more '+(item.open?'open':'')} data-idx={idx}></i>:<i data-idx={idx}></i>}
                            <div className='menu-item' data-idx={idx}><i className='collection-icon' data-idx={idx}></i>{item.title} ({item.num})</div>
                        </div>
                        </Link>
                        {item.items.length>0?<ul className={'item-list '+(item.open?'open':'')}><MenuItem menu={item.items} catePid={item.id} level={parseInt(this.props.level)+1}/></ul>:''}
                    </li>)
                })}
                <div id={"main-menu"+this.props.catePid} className='mouse-menu'>
                    <div className='item' onClick={this.showModal}>添加文件夹</div>
                    <div className='item' onClick={this.renameCate}>重命名</div>
                    <div className='item' onClick={this.removeCate}>删除文件夹</div>
                </div>
                <Modal title={this.state.modelTitle} visible={this.state.visible} onOk={this.ModelOk} onCancel={this.ModelCancel} okType="dark" closable={false}>
                    <div className='model-box'>
                        <div className='model-item'>
                        <label>名称</label>
                        <input type="text" name='domain' className='model-input' value={this.state.cateTitle} onChange={this.setCollectionValue}/>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
MenuItem.defaultProps = {
    catePid: 0
};
export default MenuItem;