import React from "react";
import Common from '../Common';
class AddCollection extends Common{
    constructor(props){
        super(props)
        this.state = {choose:0}
    }
    componentDidUpdate(prevProps){
        if(this.props.choose!==prevProps.choose){
            let choose = this.props.choose
            this.setState({
                choose:choose,
            })
        }
    }
    render(){
        return (
            <>
                <div className="btns">
                    <span>已选择{this.state.choose}项</span>
                    {this.state.choose>0&&this.props.type!=='recyclebin'?<span onClick={this.props.delCollection} className="delbtn"><i className="delete-icon"></i>  删除</span>:''}
                    {this.props.type==='recyclebin'?<span onClick={this.props.realyAllDelCollection} className="delbtn"><i className="delete-icon"></i>  全部清空</span>:''}
                    {this.state.choose>0&&this.props.type==='recyclebin'?<span onClick={this.props.realyDelCollection} className="delbtn"><i className="delete-icon"></i>  清空</span>:''}
                    {this.state.choose>0&&this.props.type==='recyclebin'?<span onClick={this.props.rebackCollection} className="backbtn"><i className="reback-icon"></i>  恢复</span>:''}
                    {this.state.choose>0&&this.props.type!=='recyclebin'?<span onClick={this.props.copyCollection} className="copybtn"><i className="copy-icon"></i>  复制链接</span>:''}
                    {/* {
                        this.state.paste?(
                        <span onClick={this.props.pasteCollection} className="copybtn"><i className="copy-icon"></i>  粘贴</span>
                        ):''
                    } */}
                    
                    <button className="mfbtn" onClick={this.props.addCollection}>添加收藏</button>
                </div>
            </>
        )
    }
}

export default AddCollection