import React from 'react';
import Common from '../Common';
import Tags from './tags';
import Collections from './collections';
import Recyclebin from './recyclebin';

class Menu extends Common{
    constructor(props){
        super(props);
        this.state={closeMenu:true}
    }
    closeMenu = ()=>{
        this.setState({
            closeMenu:!this.state.closeMenu
        })
        this.props.setMini()
    }
    setMenuUp = ()=>{
        this.props.setMenuUp()
    }
    render(){
        return(
            <div className={"menuBox "+(this.state.closeMenu?'':'mini')}>
                <div className="logoBox black"><i className={this.state.closeMenu?'close':''} onClick={this.closeMenu}></i></div>
                <div className='menu'>
                    <Tags setMenuUp={this.setMenuUp} menuUp={this.props.menuUp}/>
                    <Collections/>
                    <Recyclebin/>
                </div>
            </div>
        )
    }
    
}

export default Menu;