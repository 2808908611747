import React from 'react';
import Common from '../Common';
class Header extends Common{
    constructor(props){
        super(props)
        this.state = {search:''}
    }
    setSearchValue = (options)=>{
        this.setState({
            search:options.target.value
        })
    }
    search =(options)=>{
        if(options.code==='Enter'||options.code==='NumpadEnter'){
            localStorage.setItem('search',options.target.value)
            this.props.mainSearch()
            window.location.href='#/home?search='+options.target.value
        }
        if(options.type==='click'){
            localStorage.setItem('search',this.state.search)
            this.props.mainSearch()
            window.location.href='#/home?search='+this.state.search
        }
    }
    outLogin = ()=>{
        localStorage.removeItem('userToken')
        window.location.href='#/login'
    }
    render(){
        let username = localStorage.getItem('name')
        return(
            <div className='header'>
                <div className='searchBox'>
                    <i className='search-icon' onClick={this.search}></i>
                    <input type="text" className='search-input' placeholder='Search' value={this.state.search} onChange={this.setSearchValue} onKeyDown={this.search}/>
                </div>
                <div className='userBox'>
                   {username}
                </div>
                <div className='loginBox' onClick={this.outLogin}>
                    LOG OUT
                </div>
            </div>
        )
    }
    
}
Header.defaultProps = {
    config: {bgCss:'',hasLogBtn:false,hasSearch:false}
};
export default Header;