import React from 'react';
import Common from '../Common';
import {Link} from 'react-router-dom';
import MenuItem from './menuitem'
import { message, Modal} from 'antd';
import '../assets/css/common.css';
import '../assets/css/main.css';
class Collections extends Common{
    constructor(props){
        super(props);
        this.state = {count:0,open_status:false,select_status:false,menu:[],mousemenu:{status:false},visible:false,cateTitle:'',catePid:this.props.catePid,interval_id:0};
    }
    componentDidMount(){
        this.getMenus()
        document.addEventListener('click', this.handleClick)
    }
    componentDidUpdate=()=>{
        if(this.state.interval_id>0){
            clearInterval(this.state.interval_id)
        }
        let _self = this;
        let interval_id = setInterval(function(){
            let upmenu = _self.getMenuStatus()
            if(parseInt(upmenu)===1){
                console.log('更新menu')
                _self.getMenus()
                localStorage.setItem('upmenu',0)
            }
        },1000)
        this.state.interval_id = interval_id
       
    }
    clearSelectStatus=()=>{
        let items = document.querySelectorAll("div.menu div.selected")
        items.forEach(function(v,k){
            v.className=v.className.replace('selected','')
        })
    }
    // setSelected=(options)=>{
       
    // }
    openFolder=(options)=>{
        let target = options.target
        while(target.className.indexOf('menu-item-text')===-1){
            target = target.parentElement
        }
        this.clearSelectStatus()
        target.className = 'menu-item-text selected'

        this.setState({
            select_status:true,
            open_status:!this.state.open_status
        })
    }
    getMenus=()=>{
        let _self = this;
        this.request({
            url:'/cates',
            success:function(res){
                if(res.data.code==='0'){
                    let list = res.data.data
                    localStorage.setItem('menu',JSON.stringify(list))
                    _self.setState({
                        menu:list,
                        count:res.data.count
                    })
                }
            }
        })
    }
    contextmenu=(options)=>{
        if(this.props.type!=='recyclebin'){
            options.preventDefault()
            let mouseMenus = document.getElementsByClassName('mouse-menu')
            if(mouseMenus.length>0){
                for(let i=0;i<mouseMenus.length;i++){
                    let item = mouseMenus[i]
                    item.setAttribute('class','mouse-menu')
                }
            }
            var target = document.getElementById('main-menudd2')
            target.setAttribute('style','top:'+options.clientY+'px;left:'+options.clientX+'px')
            target.setAttribute('class','mouse-menu open')
        }
    }
    handleClick=()=>{
        let mouseMenus = document.getElementsByClassName('mouse-menu')
        if(mouseMenus.length>0){
            for(let i=0;i<mouseMenus.length;i++){
                let item = mouseMenus[i]
                item.setAttribute('class','mouse-menu')
            }
        }
    }
    componentWillUnmount() {
        // 移除事件监听
        document.removeEventListener('contextmenu', this.contextmenu)
        document.removeEventListener('click', this.handleClick)
    }
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
    addCollection=()=>{
        this.setState({
            visible:true
        })
    }
    ModelOk = () => {
        let cateTitle = this.state.cateTitle;
        let parentid = this.state.catePid;
        let menu = this.state.menu;
        let _self = this;

        let params = {title:cateTitle,parentid:parentid}
        this.request({
            url:'/cate',
            method:'POST',
            data:params,
            success:function(res){
                if(res.data.code==='0'){
                    let webData = res.data.data;
                    menu.push(
                        {id:webData,title:cateTitle,num:0,items:[],open:false},
                    )
                    localStorage.setItem('menu',JSON.stringify(menu))
                    _self.setState({
                        menu:menu,
                        visible:false,
                        formData:{domain:'',title:'',tags:[]}
                    })
                }else{
                    message.error(res.data.message)
                }
            }
        })
    }
    
    ModelCancel = () => {
        this.setState({
            visible:false
        })
    }
    setCollectionValue=(options)=>{
        let value = options.target.value;
        this.setState({
            cateTitle:value
        })
    }
    render(){
        return(
            <>
                <div className='menu-item-box'>
                    <Link to="/home">
                    <div className="menu-item-text" onContextMenu={this.contextmenu}  onClick={this.openFolder}>
                        
                        <i className={'menu-more '+(this.state.open_status?'open ':'')}></i>
                        <div className='menu-item' >
                           <i className='collection-icon'></i><span className='txt'>我的收藏 ({this.state.count})</span>
                        </div>
                        
                    </div>
                    </Link>
                    <ul className={'item-list '+(this.state.open_status?'open':'')}>
                        <MenuItem menu={this.state.menu} level="2" clearopen={this.state.open_status}/>
                    </ul>
                </div>
                <div id="main-menudd2" className='mouse-menu'>
                    <div className='item' onClick={this.showModal}>添加文件夹</div>
                </div>
                <Modal title="添加文件夹" visible={this.state.visible} onOk={this.ModelOk} onCancel={this.ModelCancel} okType="dark" closable={false}>
                    <div className='model-box'>
                        <div className='model-item'>
                            <label>名称</label>
                            <input type="text" name='domain' className='model-input' value={this.state.cateTitle} onChange={this.setCollectionValue}/>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Collections;