import React from 'react';
import Common from '../Common';
import LoginHeader from '../components/loginhead';
import {message} from 'antd';
import '../assets/css/common.css';
import '../assets/css/login.css';
class Login extends Common{
    constructor(props){
        super(props);
        let form = {username:'',password:'',checkcode:'',is_remeber:0,codekey:''}
        let placeholder = {username:'账号',password:'密码',checkcode:'验证码'}
        let error = {username:'',password:'',checkcode:''}
        this.state={
            form:form,
            placeholder:placeholder,
            error:error,
            captcha:''
        }
    }
    componentDidMount(){
        this.changeCaptcha()
    }
    setData=(options)=>{
        let name = options.target.name
        let value = options.target.value
        let form = this.state.form
        if(name==='is_remeber'){
            value = options.target.checked?1:0;
        }
        form[name] = value
        this.setState({
            'form':form
        })
        
    }
    checkData=()=>{
        let form = this.state.form
        let placeholder = {username:'账号',password:'密码',checkcode:'验证码'}
        let error = {username:'账号',password:'密码',checkcode:'验证码'}
        let checkstatus = true;
        if(form.username===''){
            placeholder.username = '请填写用户名！';
            error.username = 'error';
            checkstatus = false;
        }
        if(form.password===''){
            placeholder.password = '请填写密码！';
            error.password = 'error';
            checkstatus = false;
        }
        if(form.checkcode===''){
            placeholder.checkcode = '请填写验证码！';
            error.checkcode = 'error';
            checkstatus = false;
        }
        if(form.username.length<2 || form.username.length>10){
            placeholder.username = '用户名长度不正确，字数为2~10';
            form.username='';
            error.username = 'error';
            checkstatus = false;
        }
        var pass_patt=/[\w_!@#$%^&*]{6,16}/
        if(!pass_patt.test(form.password)){
            placeholder.password = '密码不正确，请填写“0-9,a-z,A-Z及_!@#$%^&*”且长度为6~16';
            form.password='';
            error.password = 'error';
            checkstatus = false;
        }

        var code_patt=/[\da-zA-Z]{4}/
        if(!code_patt.test(form.checkcode)){
            placeholder.checkcode = '验证码不正确';
            form.checkcode='';
            error.checkcode = 'error';
            checkstatus = false;
        }
        this.setState({
            placeholder:placeholder,
            error:error,
            form:form
        })

        return checkstatus;
    }
    submit=()=>{
        let form = this.state.form
        let _self = this;
        if(this.checkData()){
            this.request({
                url:'/login',
                data:form,
                method:'POST',
                success:function(res){
                    if(parseInt(res.data.code)===0){
                        localStorage.setItem('userToken',JSON.stringify({'token':res.data.token,'token_expire':res.data.token_expire}))
                        localStorage.setItem('name',res.data.name)
                        window.location.href="#/home"
                    }else{
                        message.warning(res.data.message)
                        _self.changeCaptcha()
                    }
                }
            })
        }
    }
    changeCaptcha=()=>{
        let _self = this;
        this.request({
            url:'/captchacode',
            success:function(res){
                let form = _self.state.form
                let data = res.data
                if(parseInt(data.code)===0){
                    form.codekey = data.data.key
                    _self.setState({
                        captcha:data.data.img,
                        form:form
                    })
                }else{
                    message.warning('验证码获取失败,请点击获取')
                }
            }
        })        
    }
    render(){
        return(
            <>
                <div className='main login-bg'>
                    <div className='main-bg'></div>
                    <LoginHeader/>
                    <div className='formBox'>
                        <div className='form'>
                            <h2>LOG IN</h2>
                            <div className='form-item'>
                                <input type="text" className={'form-input '+this.state.error.username} name='username' value={this.state.form.username} onChange={this.setData} placeholder={this.state.placeholder.username}/>
                            </div>
                            <div className='form-item'>
                                <input type="password" className={'form-input '+this.state.error.password} name="password" value={this.state.form.password} onChange={this.setData} placeholder={this.state.placeholder.password}/>
                            </div>
                            <div className='form-item'>
                                <input type="text" className={'form-input yzminput '+this.state.error.checkcode} name="checkcode" value={this.state.form.checkcode} onChange={this.setData} placeholder={this.state.placeholder.checkcode}/>
                                <img src={this.state.captcha} alt="" onClick={this.changeCaptcha} className='yzm'/>
                            </div>
                            <div className='form-item checkbox'>
                                <label htmlFor="is_remeber" className={'form-checkbox '+(this.state.form.is_remeber===1?'checked':'')}></label>
                                <input id="is_remeber" type="checkbox" className="hide" name='is_remeber' value={this.state.form.is_remeber} onChange={this.setData} /> <span style={{'marginLeft':'8px'}}>记住账户密码</span>
                            </div>
                            <div className='form-item'>
                                <button className='submit' onClick={this.submit}>登录</button>
                            </div>
                        </div>
                    </div>
                    <div className='footer copyright'>Copyright © 2022 MFCOLLECTION. All Rights Reserves.  浙ICP备15047023号 浙公网安备 33021202000871号</div>
                </div>
                
                
            </>
        )
    }
}

export default Login;