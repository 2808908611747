import React from 'react';
import Common from '../Common';
class LoginHeader extends Common{
    render(){
        return(
            <div className='header'> 
                <div className="logoBox"></div>
            </div>
        )
    }
    
}
export default LoginHeader;