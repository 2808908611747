import React from "react";
import axios from 'axios';
class Common extends React.Component {
    DOMAIIN = 'http://api-mfcollection.mfdemo.cn'
    constructor(props){
        super(props);
        this.state = {}
        this.RoutesAuth()
    }
    RoutesAuth = ()=>{
        let userToken = localStorage.getItem('userToken');
        let isLogin = false;
        if(userToken){
            userToken = JSON.parse(userToken)
            if(userToken.token_expire*1000 < new Date().getTime()){
                window.location.href='#/login';
                console.log('登录过期')
            }
            isLogin = true;
            this.userToken = userToken.token
        }
        
        let pathname = window.location.hash
        if(!isLogin&&pathname!=='#/login'){
            window.location.href='#/login';
        }else if(isLogin&&pathname==='#/login'){
            window.location.href='#/home';
        }
    }
    request=(config)=>{
        let domain = this.DOMAIIN
        config.data = config.data||{}
        config.data.token = this.userToken
        axios({
            method:config.method||'GET',
            url:config.url,
            baseURL:domain,
            params:config.data||{},
            responseType:config.type||'JSON',
            headers:config.header||{
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(config.success)
    }

    getMenuStatus=()=>{
        let status = localStorage.getItem('upmenu')
        if(status===null){
            localStorage.setItem('upmenu',0)
            status = 0
        }

        return status
    }

    getTagsStatus=()=>{
        let status = localStorage.getItem('uptags')
        if(status===null){
            localStorage.setItem('uptags',0)
            status = 0
        }

        return status
    }
}
 
export default Common;