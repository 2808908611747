import React from "react";
import Common from "./Common";
import {HashRouter,Routes,Route} from 'react-router-dom'
import Notfound from './view/404'
import Login from './view/login'
import Home from './view/home'
import Main from './components/main'

class App extends Common {
    constructor(props){
        super(props);
        this.state = {}
        this.RoutesAuth()
    }
    render(){
        return (
            <HashRouter>
                <Routes>
                    <Route path='' element={<Home />}>
                        <Route path='/' element={<Main type=""/>}></Route>
                        <Route path='/home' element={<Main type=""/>}>
                            <Route path='/home/:cid' element={<Main type=""/>}></Route>
                        </Route>
                        <Route path='/tags' element={<Main type="tags"/>}>
                            <Route path='/tags/:name' element={<Main type="tags"/>}></Route>
                        </Route>
                        <Route path='/recyclebin' element={<Main type="recyclebin"/>}></Route>
                    </Route>
                    <Route path='/login' element={<Login />}></Route>
                    <Route path='*' element={<Notfound />}></Route>
                </Routes>
            </HashRouter>
        );
    }
    
}
 
export default App;