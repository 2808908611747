import React from 'react';
import Common from '../Common';
import {Link} from 'react-router-dom';
import { message, Modal} from 'antd';
const { confirm } = Modal;
class Tags extends Common{
    constructor(props){
        super(props);
        this.state = {
            tags:[],
            allnum:0,
            open_status:false,
            mousemenu:{status:false},
            visible:false,
            tagTitle:'',
            tagIdx:0,
            interval_id:0,
        }
    }
    componentDidMount=()=>{
        this.getTags()
    }
    componentWillUnmount=()=>{
        // 移除事件监听
        document.removeEventListener('contextmenu', this.contextmenu)
        document.removeEventListener('click', this.handleClick)
    }
    componentDidUpdate=()=>{
        if(this.state.interval_id>0){
            clearInterval(this.state.interval_id)
        }
        let _self = this;
        let interval_id = setInterval(function(){
            let uptags = _self.getTagsStatus()
            if(parseInt(uptags)===1){
                console.log('更新tags')
                _self.getTags()
                localStorage.setItem('uptags',0)
            }
        },1000)
        this.state.interval_id = interval_id
    }
    getTags=()=>{
        let _self = this;
        this.request({
            url:'/tags',
            success:function(res){
                if(res.data.code==='0'){
                    // let list = res.data.data
                    _self.setState({
                        // tags:list,
                        allnum:res.data.count
                    })
                }
            }
        })
    }
    showModal=()=>{
        this.setState({
            visible:true,
            modelType:1,
            modelTitle:'添加文件夹'
        })
    }
    renameTag=()=>{
        let tagTitle = this.state.tags[this.state.tagIdx].name
        this.setState({
            visible:true,
            modelTitle:'重命名文件夹',
            tagTitle:tagTitle,
        })
    }

    removeTag=()=>{
        let _self = this;    
        let tags = this.state.tags;
        let Tag = this.state.tags[this.state.tagIdx];
        let cateid = Tag.id; 
        confirm({
            title: '是否确认删除'+Tag.title+'？',
            icon: false,
            content: '',
            okText: '删除',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                _self.delAction(cateid)
                tags.splice(_self.state.tagIdx,1)
                _self.setState({
                    tags:tags,
                    visible:false,
                })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    delAction(id){
        this.request({
            url:'/deltag/'+id,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    message.success(res.data.message)
                    
                }else{
                    message.error(res.data.message)
                }
            }
        })
    }
    ModelOk = (options) => {
        let tags = this.state.tags;
        let _self = this;
        let tagTitle = this.state.tagTitle;
        let id = tags[this.state.tagIdx].id;
        let params = {name:tagTitle}
        this.request({
            url:'/tag/'+id,
            data:params,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    tags[_self.state.tagIdx].name = tagTitle
                    _self.setState({
                        tags:tags,
                        visible:false,
                    })
                }else{
                    message.error(res.data.message)
                }
            }
        })
    }
    ModelCancel = () => {
        this.setState({
            visible:false
        })
    }
    contextmenu=(options)=>{
        if(this.props.type!=='recyclebin'){
            options.preventDefault()
            let mouseMenus = document.getElementsByClassName('mouse-menu')
            if(mouseMenus.length>0){
                for(let i=0;i<mouseMenus.length;i++){
                    let item = mouseMenus[i]
                    item.setAttribute('class','mouse-menu')
                }
            }
            let idx = options.target.dataset.idx
            this.setState({
                tagIdx:idx
            })
            var target = document.getElementById('tag-menu')
            target.setAttribute('style','top:'+options.clientY+'px;left:'+options.clientX+'px')
            target.setAttribute('class','mouse-menu open')
        }
    }
    handleClick=()=>{
        let mouseMenus = document.getElementsByClassName('mouse-menu')
        if(mouseMenus.length>0){
            for(let i=0;i<mouseMenus.length;i++){
                let item = mouseMenus[i]
                item.setAttribute('class','mouse-menu')
            }
        }
    }
    setTagValue=(options)=>{
        let value = options.target.value;
        this.setState({
            tagTitle:value
        })
    }
    openFolder=()=>{
        this.setState({
            open_status:!this.state.open_status
        })
    }
    clearSelectStatus=()=>{
        let items = document.querySelectorAll("div.menu div.selected")
        items.forEach(function(v,k){
            v.className=v.className.replace('selected','')
        })
    }
    setSelected=(options)=>{
        let target = options.target
        while(target.className.indexOf('menu-item-text')===-1){
            target = target.parentElement
        }
        this.clearSelectStatus()
        target.className = 'menu-item-text selected'
    }
    render(){
        return(
            <>
                <div className='menu-item-box'>
                    <Link to="/tags">
                    <div className='menu-item-text' onClick={this.setSelected}>
                        <i className='menu-more'></i>
                        <div className='menu-item' onClick={this.openFolder}><i className='tag-icon'></i><span className='txt'>标签 ({this.state.allnum})</span></div>
                    </div>
                    </Link>
                    {/* <ul className={'item-list '+(this.state.open_status?'open':'')}>
                        {this.state.tags.map((item,idx)=>{
                            return (
                            <li className='menu-item-box' key={item.id} onContextMenu={this.contextmenu}  data-idx={idx}>
                                <div className='menu-item-text'  onClick={this.setSelected}  data-idx={idx}>
                                    <i></i><div className='menu-item'  data-idx={idx}><Link to={"/tags/"+item.name} key={idx} data-idx={idx}><i className='tag-icon' data-idx={idx}></i>{item.name} ({item.count})</Link></div>
                                </div>
                            </li>)
                        })}
                    </ul> */}
                    <div id="tag-menu" className='mouse-menu'>
                        <div className='item' onClick={this.renameTag}>重命名</div>
                        <div className='item' onClick={this.removeTag}>删除文件夹</div>
                    </div>
                    <Modal title='重命名' visible={this.state.visible} onOk={this.ModelOk} onCancel={this.ModelCancel} okType="dark" closable={false}>
                        <div className='model-box'>
                            <div className='model-item'>
                            <label>名称</label>
                            <input type="text" name='domain' className='model-input' value={this.state.tagTitle} onChange={this.setTagValue}/>
                            </div>
                        </div>
                    </Modal>
                </div>
            </>
        )
    }
    
}

export default Tags;