import React from 'react';
import Header from '../components/head';
import Menu from '../components/menu';
import { Outlet } from 'react-router-dom';


class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {search:'',miniStatus:false,menuUp:false}
    }
    mainSearch=()=>{
        this.setState({
            search:localStorage.getItem('search')
        })
    }
    setMini=()=>{
        this.setState({
            miniStatus:!this.state.miniStatus
        })
    }
    render(){
        return(
            <>
                <Menu setMini={this.setMini} />
                <div className={"main "+(this.state.miniStatus?'mini':'')}>
                    <Header mainSearch={this.mainSearch}/>
                    <div className='content'>
                        <div className='list-main'>
                           <Outlet/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;