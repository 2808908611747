import React from 'react';
import Common from '../Common';
import { Modal,message,Tag} from 'antd';
// import { ExclamationCircleOutlined } from '@ant-design/icons';
import '../assets/css/common.css';
import '../assets/css/main.css';
import AddCollection from './addcollection';
import { useParams } from 'react-router-dom';
import loading from '../assets/img/loading.svg'
const { confirm } = Modal;
function Main(props) {
    const params = useParams();
    params.type = props.type
    return <MainSon {...params}/>;
}
class MainSon extends Common{
    constructor(props){
        super(props)
        this.state={
            prevpath:'',
            search:'',
            tags:[],
            listType:'',list:[],mousemenu:{status:false},choose:0,chooseids:[],paste:false,visible:false,confirmLoading:false,
            formData:{domain:'',title:'',tags:[],cid:this.props.cid},
            menu:[]
        }
    }

    componentDidMount(){
        if(this.props.type==='tags'){
            this.getList({type:this.props.type,name:this.props.name})
        }else if(this.props.type==='recyclebin'){
            this.getList({type:this.props.type})
        }else if(this.props.type===''){
            this.getList({type:this.props.type,cid:this.props.cid})
        }
        document.addEventListener('click', this.handleClick)
        this.getTags()
        let menu = localStorage.getItem('menu')
        if(menu){
            menu = JSON.parse(menu)
        }else{
            menu = []
        }
        if(menu&&menu.length<1){
            this.getMenus()
        }else{
            this.setState({
                menu:menu,
            })
        }
    }

    componentDidUpdate(prevProps){
        let pathname=window.location.hash
        if(this.state.prevpath!==pathname){
            let formData = this.state.formData
            formData.cid = this.props.cid
            this.setState({
                prevpath:pathname,
                formData:formData
            })
        }
        
        if(this.props.type==='tags'&&(this.props.type!==prevProps.type||this.props.name!==prevProps.name)){
            this.getList({type:this.props.type,name:this.props.name})
        }else if(this.props.type==='recyclebin'&&this.props.type!==prevProps.type){
            this.getList({type:this.props.type})
        }else if(this.props.type===''&&(this.props.type!==prevProps.type||this.props.cid!==prevProps.cid||this.state.prevpath!==pathname)){
            let param = pathname.split('?')
            let search = '';
            if(param.length>1){
                let searchP = param[1].split('=')
                if(searchP.length>1){
                    search = searchP[1]
                }
            }
            
            this.getList({type:this.props.type,cid:this.props.cid,search:search})
        }
    }

    getTags=()=>{
        let _self = this;
        this.request({
            url:'/tags',
            success:function(res){
                if(res.data.code==='0'){
                    let list = res.data.data
                    _self.setState({
                        tags:list,
                    })
                }
            }
        })
    }
    getList=(param)=>{
        let _self = this;
        this.request({
            url:'/collections',
            data:param,
            success:function(res){
                if(res.data.code==='0'){
                    let list = res.data.data
                    _self.setState({
                        list:list
                    })
                }
            }
        })
    }
    getMenus=()=>{
        let _self = this;
        this.request({
            url:'/cates',
            success:function(res){
                if(res.data.code==='0'){
                    let list = res.data.data
                    localStorage.setItem('menu',JSON.stringify(list))
                    _self.setState({
                        menu:list
                    })
                }
            }
        })
    }
    setListType=(options)=>{
        this.setState({
            listType:options.target.dataset.type
        })
    }
    delCollection=()=>{
        let _self = this;
        if(this.state.choose===0){
            message.warning({
                icon:false,
                content:'缺少需要删除的内容'
            })
            return false
        }
        
        let chooseids = this.state.chooseids;
        let list = this.state.list;
        confirm({
            title: '是否确认删除所选内容？',
            icon: false,
            content: '',
            okText: '删除',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                let nlist = []
                list.forEach(function(item,k){
                    if(chooseids.indexOf(item.id)===-1){
                        nlist.push(item)
                    }else{
                        _self.delAction(item.id)
                    }
                })
                _self.clearChoose()
                _self.setState({
                    mousemenu:{status:false},
                    choose:0,chooseids:[],list:nlist
                })
                
                
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    delAction(id){
        this.request({
            url:'/collection/'+id,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    message.success(res.data.message)
                }
            }
        })
    }

    realyAllDelCollection=()=>{
        let _self = this;
        let list = this.state.list;
        confirm({
            title: '是否确认清空所选内容？',
            icon: false,
            content: '',
            okText: '删除',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                list.forEach(function(item){
                    _self.realyDelAction(item.id)
                })
                _self.setState({
                    mousemenu:{status:false},
                    choose:0,chooseids:[],list:[]
                })
                
                
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    realyDelCollection=()=>{
        let _self = this;
        if(this.state.choose===0){
            message.warning({
                icon:false,
                content:'缺少需要删除的内容'
            })
            return false
        }
        
        let chooseids = this.state.chooseids;
        let list = this.state.list;
        confirm({
            title: '是否确认清空所选内容？',
            icon: false,
            content: '',
            okText: '删除',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                let nlist = []
                list.forEach(function(item,k){
                    if(chooseids.indexOf(item.id)===-1){
                        nlist.push(item)
                    }else{
                        _self.realyDelAction(item.id)
                    }
                })
                _self.clearChoose()
                _self.setState({
                    mousemenu:{status:false},
                    choose:0,chooseids:[],list:nlist
                })
                
                
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    realyDelAction(id){
        this.request({
            url:'/collection/clear/'+id,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    message.success(res.data.message)
                }
            }
        })
    }

    rebackCollection=()=>{
        let _self = this;
        if(this.state.choose===0){
            message.warning({
                icon:false,
                content:'缺少需要恢复的内容'
            })
            return false
        }
        
        let chooseids = this.state.chooseids;
        let list = this.state.list;
        confirm({
            title: '是否确认恢复所选内容？',
            icon: false,
            content: '',
            okText: '恢复',
            okType: 'dark',
            cancelText: '取消',
            onOk() {
                let nlist = []
                list.forEach(function(item,k){
                    if(chooseids.indexOf(item.id)===-1){
                        nlist.push(item)
                    }else{
                        _self.rebackAction(item.id)
                    }
                })
                _self.clearChoose()
                _self.setState({
                    mousemenu:{status:false},
                    choose:0,chooseids:[],list:nlist
                })
                
                
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
   rebackAction(id){
        this.request({
            url:'/collection/back/'+id,
            method:'POST',
            success:function(res){
                if(res.data.code==='0'){
                    message.success(res.data.message)
                }
            }
        })
    }
    addCollection=()=>{
        this.setState({
            visible:true
        })
    }
    setCollectionValue=(options)=>{
        let name = options.target.name;
        let value = options.target.value;
        let formData = this.state.formData;
        
        formData[name] = value;
        this.setState({
            formData:formData
        })
    }
    getWebTitle=(options)=>{
        let _self = this
        let domain = options.target.value
        if(domain.match(/(http:\/\/|https:\/\/)/)===null){
            domain = 'http://'+domain
        }
        let tmpName = domain.match(/(http|https):\/\/([a-zA-Z-]+\.){0,1}([a-zA-Z-]+)\.([a-zA-Z-]+)/)
        // console.log(match)
        let formData = _self.state.formData;
        formData.domain = domain
        this.state.tmptitle = tmpName[3]
        _self.setState({
            tmptitle:tmpName[3],
            formData:formData
        })
        this.request({
            url:'/get_web_title',
            data:{domain:encodeURIComponent(domain)},
            success:function(res){
                if(res.data.code==='0'){
                    formData.title = res.data.data
                }else{
                    // message.error(res.data.message)
                    formData.title = tmpName[3]
                }
                if(_self.state.formData.domain!==''){
                    _self.setState({
                        formData:formData
                    })
                }
               
            }
        })
    }
    copyCollection=()=>{
        if(this.state.choose===0){
            message.warning('请选择需要复制的内容')
        }else{
            let chooses = document.getElementsByClassName('choose');
            let copys = '';
            for(var i=0;i<chooses.length;i++){
                let item = chooses[i]; 
                copys = copys + item.dataset.domain +"\n";
            }
            let myfun = function(e){
                e.clipboardData.setData('text/plain', copys);
                e.preventDefault();
            }
            document.addEventListener('copy', myfun);
            document.execCommand("Copy");
            document.removeEventListener('copy',myfun)
            message.success('复制成功')
            this.setState({
                mousemenu:{status:false},
                paste:true
            })
        }
    }
    // pasteCollection=()=>{
    //     let list = localStorage.getItem('copys')
    //     list.forEach(function(item,k){
    //         console.log(item)
    //         let param = JSON.parse(item.param)
    //         console.log(param)
    //     })
    // }
    chooseCollection=(options)=>{
        options.preventDefault()
        let flag=true
        let node = options.target
        let num = 1
        let chooseids = this.state.chooseids 
        while(flag && num<15){
            if(node.className.search('list-item')===-1){
                node = node.parentNode
            }else{
                break;
            }
            num++
        }
        
        let id = node.dataset.id
        let classname = node.className.trim('')
        let hasChoose = node.className.search('choose')===-1
        if(!options.ctrlKey&&!options.metaKey){
            let allnode = node.parentNode.childNodes
            allnode.forEach(function(item,k){
                let item_classname = item.className
                if(item.className.search('choose')>-1){
                    item_classname = 'list-item'
                    let sidx = chooseids.indexOf(id)
                    chooseids.splice(sidx,1)
                }
                item.className = item_classname
            })
        }

        if(hasChoose){
            classname = 'list-item choose'
            if(chooseids.indexOf(id)===-1){
                chooseids.push(parseInt(id))
            }
        }else{
            classname = 'list-item'
            let sidx = chooseids.indexOf(id)
            chooseids.splice(sidx,1)
        }
        node.className = classname

        this.setState({
            choose:document.getElementsByClassName('choose').length,
            chooseids:chooseids
        })
    }
    clearChoose(){
        let allnode = document.getElementsByClassName('list-item')
        for(var i=0;i<allnode.length;i++){
            let item = allnode[i];
            if(item.className.search('zw')>-1){
                continue;
            }
            item.className = 'list-item'
        }
    }
    contextmenu=(options)=>{
        // if(this.props.type!=='recyclebin'){
        //     options.preventDefault()
        //     let mouseMenus = document.getElementsByClassName('mouse-menu')
        //     if(mouseMenus.length>0){
        //         for(let i=0;i<mouseMenus.length;i++){
        //             let item = mouseMenus[i]
        //             item.setAttribute('class','mouse-menu')
        //         }
        //     }
        //     // this.setState({
        //     //     mousemenu:{status:true,choose:0}
        //     // })
        //     let target = document.getElementById('main-menu')
        //     target.setAttribute('style','top:'+options.clientY+'px;left:'+options.clientX+'px')
        //     target.setAttribute('class','mouse-menu open')
            
        // }
    }
    handleClick=()=>{
        let mouseMenus = document.getElementsByClassName('mouse-menu')
        if(mouseMenus.length>0){
            for(let i=0;i<mouseMenus.length;i++){
                let item = mouseMenus[i]
                item.setAttribute('class','mouse-menu')
            }
        }
    }
    componentWillUnmount() {
        // console.log(111)
        // // 移除事件监听
        // document.removeEventListener('contextmenu', this.contextmenu)
        // document.removeEventListener('click', this.handleClick)
    }
    
    ModelOk = (options) => {
        let formData = this.state.formData;
        let list = this.state.list;
        let _self = this;
        let tags = [];
        formData.tags.forEach(function(item){
            tags.push(item.title)
        })
        if(formData.domain===''){
            message.error('请填写需要收藏的链接')
            return false
        }
        tags = tags.join(',')
        let params = {title:formData.title?formData.title:this.state.tmptitle,domain:formData.domain,tags:tags,cid:formData.cid}
        let webtitle = formData.title
        if(params.title===''&&params.title==='Access denied'){
            formData.title = this.state.tmptitle
            this.setState({
                formData:formData,
            })
            webtitle = this.state.tmptitle
            // message.error('标题不能为空')
            // return false;
        }
        this.setState({
            confirmLoading:true,
        })
        this.request({
            url:'/collection',
            method:'POST',
            data:params,
            success:function(res){
                if(res.data.code==='0'){
                    let webData = res.data.data;
                    if(webData){
                        list.push(
                            {id:webData.id,title:webtitle,ico:webData.ico,cover:'',domain:formData.domain,tags:webData.tags},
                        )
                        _self.setState({
                            list:list,
                            visible:false,
                            formData:{domain:'',title:'',tags:[]},
                            confirmLoading:false,
                        })
                        localStorage.setItem('upmenu',1)
                        localStorage.setItem('uptags',1)
                        _self.getTags();
                        let getnum = 1;
                        let time = setInterval(function(){
                            if(getnum>120){
                                message.error(webData.title+'封面获取失败')
                                clearInterval(time)
                            }
                            _self.request({
                                url:'/get_cover/'+webData.id,
                                success:function(res){
                                    if(res.data.code==='0'){
                                        let collection = res.data.data;
                                        let nlist = _self.state.list;
                                        for(let i=0;i<nlist.length;i++){
                                            let item = nlist[i];
                                            if(item.id===webData.id){
                                                item.cover=collection.cover
                                                item.title=collection.title
                                                item.ico=collection.ico
                                                item.domain=collection.domain
                                            }
                                        }
                                    
                                        _self.setState({
                                            list:nlist,
                                        })
                                        clearInterval(time)
                                    }
                                }
                            })
                            getnum++
                        },1500)
                    }
                }
            },
            error:function(e){
                _self.setState({
                    confirmLoading:true,
                })
            }
        })
    }

    ModelCancel = () => {
        this.setState({
            visible:false,
            confirmLoading:false,
        })
    }
    addTags=(options)=>{
        // console.log(options.key)
        if(options.key==='Enter'&&options.target.value!==''){
            let formData = this.state.formData
            formData.tags.push({id:null,title:options.target.value,show:true})
            this.setState({
                formData:formData
            })
            options.target.value=''
        }
        
        
    }

    setMenu=(item,level=1)=>{
        let _self = this
        let space = level===0?'':'|'
        for(let i=0;i<level;i++){
            space = space.concat('--');
        }
        let options = [<option key={item.id} value={item.id}>{space+item.title}</option>];
        if(item.items&&item.items.length>0){
            let nlevel = level+1
            item.items.forEach(function(value){
                let items = _self.setMenu(value,nlevel)
                options.push(items)
            })
        }
        return options
    }
    render(){
        
        let tagsLabel = '';
        if(this.props.type==='tags'){
            tagsLabel = <div className='tags'>
                            <div className='tag-item' onClick={()=>{this.getList({type:'tags',name:''})}}># 全部</div>
                            {this.state.tags.map((sitem,sidx)=>{
                                return <div className='tag-item' onClick={()=>{this.getList({type:'tags',name:sitem.name})}} key={sidx}># {sitem.name}</div> 
                            })}
                         </div>
        }
                    
        return(
            <>
                <div className='list-head'>
                    <div className='view-group'>
                        <div className='view-pic' onClick={this.setListType} data-type='type-pic'></div>
                        <div className='view-text' onClick={this.setListType} data-type='type-text'></div>
                    </div>
                    <div className='view-group right'>
                        <AddCollection addCollection={this.addCollection} delCollection={this.delCollection} copyCollection={this.copyCollection} realyAllDelCollection={this.realyAllDelCollection} realyDelCollection={this.realyDelCollection} rebackCollection={this.rebackCollection} choose={this.state.choose} visible={this.state.visible} type={this.props.type}/>
                    </div>
                    {tagsLabel}
                </div>
                <div className='list-body'>
                    <div className={'list-box '+this.state.listType}>
                        {this.state.list.map((item,idx)=>{
                            return ( 
                                <div className='list-item' key={idx} data-id={item.id} data-domain={item.domain}  onContextMenu={this.contextmenu}>
                                    <div className='choose-ico'></div>
                                    {item.cover!==''?<div className='pic' onClick={this.chooseCollection}><img src={item.cover} alt="" /></div>:<div className='nopic' onClick={this.chooseCollection}><img src={loading} alt="" /></div>}
                                    
                                    <div className='cont'>
                                        <div className='title'>{item.title}</div>
                                        <div className='ico'>
                                            <img src={item.ico} alt="" /> 
                                        </div>
                                        <div className='link'>
                                            <span><a href={"http://"+item.domain} target="_blank " > {item.domain}</a></span>
                                        </div>
                                        <div className='tags'>
                                            {item.tags.map((sitem,sidx)=>{
                                                return <div className='tag-item' key={sidx}># {sitem}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='list-item zw'></div>
                        <div className='list-item zw'></div>
                        <div className='list-item zw'></div>
                        <div className='list-item zw'></div>
                    </div>
                </div>
                {/* <div id="main-menu" className='mouse-menu'>
                    <div className='item' onClick={this.delCollection}>删除所选</div>
                    <div className='item' onClick={this.copyCollection}>复制所选 ({this.state.choose}) 网站</div>
                </div> */}
                <input className='pasteBox' id='pasteBox'></input>
                <Modal title="添加收藏" cancelText='取消' okText='保存' visible={this.state.visible} onOk={this.ModelOk} onCancel={this.ModelCancel} confirmLoading={this.state.confirmLoading} okType="dark" closable={false} maskClosable={false}>
                    <div className='model-box'>
                        <div className='model-item'>
                            <label>当前分类</label>
                            <select name="cid" id="cate" className='model-input' onChange={this.setCollectionValue} value={this.state.formData.cid}>
                                <option value="0">我的收藏</option>
                                {this.state.menu.map((item,k)=>{
                                    let items = this.setMenu(item)
                                    return items
                                })}
                                
                            </select>
                            {/* <input type="text" name='domain' className='model-input' value={this.state.formData.cid} onChange={this.setCollectionValue} onBlur={this.getWebTitle}/> */}
                        </div>
                        <div className='model-item'>
                            <label>链接</label>
                            <input type="text" name='domain' className='model-input' value={this.state.formData.domain} onChange={this.setCollectionValue} onBlur={this.getWebTitle}/>
                        </div>
                        <div className='model-item'>
                            <label>标题</label>
                            <input type="text" name='title' className='model-input' value={this.state.formData.title} onChange={this.setCollectionValue}/>
                        </div>
                        <div className='model-item'>
                            <label>标签</label>
                            <input type="hidden" name='tags' className='model-input' value={this.state.formData.tags} onChange={this.setCollectionValue}></input>
                            <div className='model-input'>
                                <input type="text" onKeyUp={this.addTags} placeholder="输入标签后回车"/>
                                {this.state.formData.tags.map((item,key)=>{
                                    return <Tag closable visible={item.show } key={key} data-key={key} onClose={(key)=>{
                                        let formData = this.state.formData
                                        formData.tags.splice(key,1)
                                        this.setState({
                                            formData:formData
                                        })
                                    }}>{item.title}</Tag>
                                })}
                                
                            </div>
                            
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
    
}


export default Main;